// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";
import ptTranslation from "./locales/pt/translation.json";

// Configuração do i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
  },
  lng: "pt", // idioma padrão
  fallbackLng: "pt", // idioma padrão se o idioma selecionado não for encontrado
  interpolation: {
    escapeValue: false, // react já faz a escape das variáveis
  },
});

export default i18n;
