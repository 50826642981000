import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook

import "../index.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function LoginPage() {
  const toast = useRef(null);
  const [email, setEmail] = useState("");
  const [username, setUser] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize the useNavigate hook
  //const [errorMessage, setErrorMessage] = (useState < string) | (null > null);

  useEffect(() => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("XSRF-TOKEN");
    getCsrfToken();
  }, []);

  const getCsrfToken = async () => {
    try {
      const cookie = await axios.get(
        import.meta.env.VITE_API_URL + "/get-csrf-token",
        {
          withCredentials: true,
        }
      );
      localStorage.setItem("XSRF-TOKEN", cookie.data.csrfToken);
    } catch (error) {
      console.error("Could not get CSRF token", error);
      showError(t("GENERAL_ERR"));
    }
  };

  const showError = (message) => {
    toast.current?.show({
      severity: "error",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 5000,
    });
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 5000,
    });
  };

  const clear = () => {
    if (toast.current) toast.current.clear();
  };

  const [IsRecovering, setIsRecovering] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();

    // Get the CSRF token from the cookie
    const csrfToken = localStorage.getItem("XSRF-TOKEN");

    if (!csrfToken) return;

    if (IsRecovering) return;
    setIsRecovering(true);

    clear();

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/RecoverPW",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
            "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
          },
          credentials: "include",
          body: JSON.stringify({ username, email }),
        }
      );

      if (response.ok) {
        setUser("");
        setEmail("");
        showNiceMessage(t("RECOVER_PASSWORD_MESSAGE") + " " + email);
      } else {
        showError(t("RECOVER_PASSWORD_WARNING"));
      }
    } catch (error) {
      showError(t("RECOVER_PASSWORD_WARNING"));

      console.error("An error occurred:", error);
    } finally {
      setIsRecovering(false);
    }
  };

  return (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "50%" }}
      >
        <div className="col-md-3">
          <div className="text-center">
            <img
              src="/images/logoG.png"
              alt="logo"
              style={{ marginBottom: "2em", marginTop: "4em", width: "100%" }}
            />
          </div>
          {/*    {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}{" "} */}
          {/* <-- Add this line */}
          <form onSubmit={handleLogin}>
            <div
              className="form-group"
              style={{
                marginBottom: "0em",
              }}
            >
              <label htmlFor="User"> </label>
              <input
                type="text"
                id="user"
                name="user"
                value={username}
                onChange={(e) => setUser(e.target.value)}
                className="form-control"
                placeholder={t("USERNAME")}
                style={{
                  marginBottom: "0em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email"> </label>
              <input
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email}
                style={{
                  marginBottom: "2em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-outline-dark btn-block "
              style={{
                fontSize: "16px", // Match the input field font size
                fontWeight: "400", // Adjust weight as needed
                padding: "5px 0", // Adjust padding as needed
                borderRadius: "20px", // Match the input field border radius
                width: "100%",
                boxSizing: "border-box", // Include padding and border in the width and height calculation
                color: "#f5862c", // Definindo a cor do texto como laranja
                backgroundColor: "#fff", // Definindo a cor de fundo como branco
                borderColor: "#f5862c",
              }}
            >
              {t("RECOVER_PASSWORD")}
            </button>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <button
                className="btn-link"
                onClick={() => navigate("/login")}
                style={{
                  color: "#f5862c",
                  fontSize: "13px",
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {t("RETURN_LOGIN")}
              </button>
            </div>
          </form>
          <Toast ref={toast} position="top-center" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
