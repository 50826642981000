import { useRef, useState } from "react";
import { Toast } from "primereact/toast";

import "../index.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function LoginPage() {
  const toast = useRef(null);
  const [passwordNova, setNova] = useState("");
  const [passwordAntiga, setAntiga] = useState("");
  const { t } = useTranslation();
  //const [errorMessage, setErrorMessage] = (useState < string) | (null > null);

  const showError = (message) => {
    toast.current?.show({
      severity: "error",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 3000,
    });
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 3000,
    });
  };

  const clear = () => {
    if (toast.current) toast.current.clear();
  };

  const [IsChanging, setIsChanging] = useState(false);

  const handleLogin = async (event) => {
    // Get the CSRF token from the cookie
    const csrfToken = localStorage.getItem("XSRF-TOKEN");
    const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

    if (!csrfToken) return;
    if (!token) return;

    if (IsChanging) return;
    setIsChanging(true);

    event.preventDefault();

    clear();

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/changePassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ passwordAntiga, passwordNova }),
        }
      );

      if (response.ok) {
        setAntiga("");
        setNova("");
        showNiceMessage(t("CHANGE_PW_INFO_SUCCESS"));
      } else {
        showError(t("CHANGE_PW_INFO"));
      }
    } catch (error) {
      showError("Erro.");
      console.error("An error occurred:", error);
    } finally {
      setIsChanging(false);
    }
  };
  return (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "50%" }}
      >
        <div className="col-md-3" style={{ paddingTop: "10%" }}>
          <form onSubmit={handleLogin}>
            <div
              className="form-group"
              style={{
                marginBottom: "0em",
              }}
            >
              <label htmlFor="User"> </label>
              <input
                type="password"
                id="user"
                name="user"
                value={passwordAntiga}
                onChange={(e) => setAntiga(e.target.value)}
                className="form-control"
                placeholder={t("OLD_PW")}
                style={{
                  marginBottom: "0em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="passwordNova"> </label>
              <input
                type="password"
                id="passwordNova"
                name="passwordNova"
                className="form-control"
                placeholder={t("NEW_PW")}
                value={passwordNova}
                style={{
                  marginBottom: "2em",
                  fontSize: "16px",
                  borderRadius: "20px",
                }}
                onChange={(e) => setNova(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="btn btn-outline-dark btn-block "
              style={{
                fontSize: "16px", // Match the input field font size
                fontWeight: "400", // Adjust weight as needed
                padding: "5px 0", // Adjust padding as needed
                borderRadius: "20px", // Match the input field border radius
                width: "100%",
                boxSizing: "border-box", // Include padding and border in the width and height calculation
                color: "#f5862c", // Definindo a cor do texto como laranja
                backgroundColor: "#fff", // Definindo a cor de fundo como branco
                borderColor: "#f5862c",
              }}
            >
              {t("CHANGE_PW")}
            </button>
          </form>
          <Toast ref={toast} position="top-center" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
