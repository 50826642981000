/* eslint-disable @next/next/no-img-element */
"use client";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
interface Trabalhador {
  id: number;
  trabalhador: string;
}
const DelegarChefia = () => {
  const { t } = useTranslation();
  const [listaTrabalhadoresChefia, setTrabalhadoresChefia] = useState<Falta[]>(
    []
  );
  const showError = (message) => {
    toast.current?.show({
      severity: "error",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2000,
    });
  };

  const showNiceMessage = (message) => {
    toast.current?.show({
      severity: "success",
      detail: <span dangerouslySetInnerHTML={{ __html: message }}></span>,
      sticky: false,
      life: 2500,
    });
  };

  const csrfToken = localStorage.getItem("XSRF-TOKEN");
  const token = localStorage.getItem("jwtToken"); // Retrieve the token from the cookie or storage

  useEffect(() => {
    fetchUpcomingEventsData9();
    fetchUpcomingEventsData2();
    fetchUpcomingEventsData4();
    fetchUpcomingEventsData1();
  }, []);

  async function fetchUpcomingEventsData9() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getTrabalhadoresChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].error === "1") setTrabalhadoresChefia([]);
        else setTrabalhadoresChefia(data);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [chefes, setChefes] = useState<Trabalhador[]>([]);
  const [selectedChefe2, setSelectedChefe2] = useState<Trabalhador | null>(
    null
  );

  async function fetchUpcomingEventsData2() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getChefes",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setChefes(
          data.map((dado: { id: number; nome: string }) => ({
            id: dado.id,
            trabalhador: dado.nome,
          }))
        );
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [isChefeDelegado, setIsChefeDelegado] = useState<boolean | null>(null);

  async function fetchUpcomingEventsData4() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/isChefeDelegado",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].Success === 1) setIsChefeDelegado(true);
        else if (data[0].Success === 0) setIsChefeDelegado(false);
        else console.error("An error occurred");
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  const [errorMessage2, setErrorMessage2] = useState("");

  async function fetchUpcomingEventsData1() {
    try {
      if (token && csrfToken) {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/getDelegadosChefia",
          {
            method: "POST",
            headers: {
              "x-auth-token": token,
              "x-xsrf-token": csrfToken,
              "Accept-Language": i18n.language, // Definir o valor dinâmico do idioma
            },
            credentials: "include", // Use "include" to include credentials in the request
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data[0].error === "1")
          setErrorMessage2(t("WARNING_DELEGATE_MANAGEMENT"));
        else
          setErrorMessage2(t("DELEGATION_MANAGEMENT_SUCCESS") + data[0].nome);
      }
    } catch (error) {
      console.error("An error occurred", error);
      //setUpcomingEventsData([]);
    }
  }

  type Falta = {
    nome: string;
    username: string;
    nome_chefe: string;
    refeicao: string;
    dias: string;
    majoracao: string;
    gozados: string;
    pendentes: string;
    ativo: string;
    [key: string]: string | number;
  };

  const [editDiasCompletosDialog, setEditDiasCompletosDialog] = useState(false);

  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");

  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable<any>>(null);

  const clean = () => {
    setSelectedChefe2(null);
    document.body.classList.add("no-scroll");
  };

  const openDiasCompletos = () => {
    handleVacationButtonClick();
    clean();
    setHeader(t("DELEGATE_MANAGEMENT"));
    setIcon("pi-arrow-right");
    setEditDiasCompletosDialog(true);
  };

  const hideEditDelegarChefia = () => {
    setEditDiasCompletosDialog(false);
    close();
  };

  const close = () => {
    document.body.classList.remove("no-scroll");
    handleVacationButtonClick2();
    setSelectedRow(null); // Limpa a seleção de todas as linhas
  };

  const [IsDelegating, setIsDelegating] = useState(false);

  const delegarChefiaFunction = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsDelegating) return;
    setIsDelegating(true);

    if (selectedChefe2 !== null && selectedChefe2.id > 0) {
      setErrorMessage("");
    } else {
      setIsDelegating(false);
      setErrorMessage(t("WORKER_ERR7"));
      return;
    }
    let id_chefe = selectedChefe2.id;

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/delegarChefia",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
          body: JSON.stringify({ id_chefe }),
        }
      );

      if (response.ok) {
        fetchUpcomingEventsData1();
        fetchUpcomingEventsData9();
        fetchUpcomingEventsData4();
        showNiceMessage(t("DELEGATE_MANAGEMENT_SUCCESS"));
        hideEditDelegarChefia();
      } else {
        showError(t("ERR"));
      }
    } catch (error) {
      showError(t("ERR"));
      console.error("An error occurred:", error);
    } finally {
      setIsDelegating(false);
    }
  };
  const [IsDeleting, setIsDeleting] = useState(false);

  const retirarDelegacao = async () => {
    if (!csrfToken) return;
    if (!token) return;

    if (IsDeleting) return;
    setIsDeleting(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/deleteDelegarChefia",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-xsrf-token": csrfToken, // Add CSRF token in the request header
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        fetchUpcomingEventsData1();
        fetchUpcomingEventsData9();
        fetchUpcomingEventsData4();
        showNiceMessage(t("WITHDRAW_MANAGEMENT_SUCCESS"));
      } else {
        showError(t("ERR2"));
      }
    } catch (error) {
      showError(t("ERR2"));
      console.error("An error occurred:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const confirm1 = (rowData) => {
    confirmDialog({
      message: t("WITHDRAW_MANAGEMENT_QUESTION"),
    });
  };

  const currentPageTemplate = `{currentPage} ${t("OF2")} {totalPages}`;

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2 ">
          {isChefeDelegado === true && (
            <Button
              label={t("DELEGATE_MANAGEMENT")}
              icon="pi pi-arrow-right"
              rounded
              className="mr-2"
              onClick={openDiasCompletos}
              style={{
                padding: "0.25rem 1rem",
                borderRadius: "20px", // Match the input field border radius
              }}
            />
          )}

          {isChefeDelegado === false && (
            <Button
              label={t("REMOVE_DELEGATION")}
              icon="pi pi-arrow-left"
              rounded
              className="mr-2"
              onClick={() => confirm1(true)}
              style={{
                padding: "0.25rem 1rem",
                borderRadius: "20px", // Match the input field border radius
              }}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  const [maxWidth, setMaxWidth] = useState("100%");

  const handleVacationButtonClick = () => {
    if (window.innerWidth > 1000) {
      setMaxWidth("82%");
    }
  };
  const handleVacationButtonClick2 = () => {
    setMaxWidth("100%");
  };

  const delegarFooter = (
    <>
      <Button
        label={t("CONFIRM")}
        icon="pi pi-check"
        text
        onClick={delegarChefiaFunction}
        style={{
          padding: "0.25rem 1rem",
          borderRadius: "20px", // Match the input field border radius
        }}
      />
    </>
  );

  const EditHeader = () => (
    <div>
      <i
        className={`pi ${icon}`}
        style={{ fontSize: "1.2rem", marginRight: "0.55rem" }}
      ></i>
      <label style={{ fontSize: "1.2rem" }}>{header}</label>
    </div>
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);

  // Função para aplicar uma classe condicional às linhas
  const rowClassName = (rowData) => {
    return rowData.id === selectedRow ? "highlight" : "";
  };

  return (
    <div className="grid crud-demo" style={{ marginTop: "1rem" }}>
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <Toolbar
            className="botoes datatable-transition"
            style={{ padding: "0.3rem", maxWidth: maxWidth }}
            left={leftToolbarTemplate}
          ></Toolbar>
          {errorMessage2 && (
            <p
              id="erro2"
              style={{
                color: import.meta.env.VITE_API_COLOR,
                marginLeft: "0.5rem",
              }}
            >
              {errorMessage2}
            </p>
          )}
          <DataTable
            ref={dt}
            value={listaTrabalhadoresChefia}
            dataKey="ID"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 100]}
            className="datatable-responsive datatable-transition"
            style={{ fontSize: "0.8rem", maxWidth: maxWidth }}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={currentPageTemplate}
            emptyMessage={t("DELEGATE_MANAGEMENT_NO_WORKERS")}
            responsiveLayout="scroll"
            rowClassName={rowClassName}
            stripedRows
          >
            <Column
              field="nome"
              header={t("COLUMN_DELEGATE_MANAGEMENT")}
              style={{ minWidth: "5rem" }}
              headerClassName="center-align"
              bodyClassName="center-align"
            ></Column>
          </DataTable>

          <Dialog
            visible={editDiasCompletosDialog}
            position={"right"}
            style={{ width: "350px", height: "97%" }}
            header={<EditHeader />}
            modal
            draggable={false}
            className="p-fluid product-dialog"
            footer={delegarFooter}
            onHide={hideEditDelegarChefia}
          >
            <div className="field ">
              <label
                htmlFor="Chefe"
                style={{ fontSize: "0.9rem", color: "#323232" }}
              >
                {t("DELEGATE_MANAGEMEN2")}
              </label>
              <Dropdown
                value={selectedChefe2}
                onChange={(e) => setSelectedChefe2(e.value)}
                options={chefes}
                optionLabel="trabalhador"
                className="w-full     md:w-19rem "
              />
            </div>

            <p id="erro2" style={{ color: "red" }}>
              {errorMessage}
            </p>
          </Dialog>

          <ConfirmDialog
            draggable={true}
            content={({ headerRef, contentRef, footerRef, hide, message }) => (
              <div className="flex flex-column align-items-center   surface-overlay border-round2">
                <span
                  className="font-bold text-2xl   mb-2 mt-4"
                  ref={headerRef}
                >
                  {message.header}
                </span>
                <p className="mb-0" ref={contentRef}>
                  {message.message}
                </p>
                <div
                  className="flex align-items-center gap-2 mt-4"
                  ref={footerRef}
                >
                  <Button
                    className="pencil pencil2"
                    label={t("CANCEL")}
                    text
                    onClick={(event) => {
                      hide(event);
                      // reject();
                    }}
                    style={{
                      background: "white",
                      padding: "0.25rem 1rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  ></Button>
                  <Button
                    label={t("YES")}
                    text
                    onClick={(event) => {
                      retirarDelegacao();
                      hide(event);
                    }}
                    style={{
                      padding: "0.25rem 2rem",
                      borderRadius: "20px", // Match the input field border radius
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
        <Toast ref={toast} position="top-center" />
      </div>
    </div>
  );
};

export default DelegarChefia;
